import { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, GridProps, styled } from "@mui/material";
import NavigationBar, { NavigationFooter, NavigationItem } from "@jbhi-fi/lanyard-ui/components/NavigationBar";
import { useLocation } from "react-router-dom";
import { getConsts } from "../../utils/consts";
import { useCountry } from "src/components/Context/CountryContext";
import SquareSwitch from "./SquareSwitch";
import {
  Close,
  EmailOutlined,
  HelpOutlineOutlined,
  HomeOutlined,
  ImageOutlined,
  LocationOnOutlined,
  Logout,
  NewReleasesOutlined,
  PriceChangeOutlined,
  SettingsOutlined,
  SellOutlined,
  Microsoft,
} from "@mui/icons-material";
import { hasPermission } from "src/infrastructure/auth/PermissionUtils";
import { UserProfile } from "src/infrastructure/auth/UserProfile";
import Button from "@jbhi-fi/lanyard-ui/components/Button/Button";

import { UserRoles } from "src/infrastructure/auth/UserRoles";
import { useIsMobile } from "src/hooks/useIsMobile";

const StyledGridContainer = styled(Grid)<GridProps>(({ theme }) => ({
  backgroundColor: "#000",
}));

type ChildItem = {
  title: string;
  path: string;
  icon: JSX.Element;
  constName: string;
  permissionName?: string;
  hideOnMobile?: boolean;
};

type DrawerMenuProps = {
  isMenuOpen: boolean;
  userProfile: UserProfile;
  onMenuItemSelected: (path: string) => void;
  onDrawerClose: () => void;
  onLogout: () => void;
};

const StyledNav = styled('nav')(({ theme }) => ({
  '& ul': {
    height: '100%',
    width: '350px',
  },
  '& ul[class*="container_show_false"]': {
    transform: 'translateX(-350px)',
  },
  '& ul li': {
    fontSize: '18px',
  },
  '& ul li.title': {
    fontSize: '20px',
  },
}));

export const DrawerMenu: React.FC<DrawerMenuProps> = ({
  isMenuOpen,
  userProfile,
  onMenuItemSelected,
  onDrawerClose,
  onLogout,
}: DrawerMenuProps) => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const { country, canSwitchCountries, changeCountry } = useCountry();
  const consts = getConsts();

  const menuItems: ChildItem[] = [
    {
      title: "Home",
      path: consts.routerPath.home,
      constName: consts.page.home,
      icon: <HomeOutlined fontSize="small" />,
    },
    {
      title: "Was Prices",
      path: consts.routerPath.wasPrice,
      constName: consts.page.wasPrice,
      icon: <PriceChangeOutlined fontSize="small" />,
      permissionName: UserRoles.PricingWasPrices,
      hideOnMobile: true,
    },
    {
      title: "Historic Pricing",
      path: consts.routerPath.historicPricing,
      constName: consts.page.historicPricing,
      icon: <SellOutlined fontSize="small" sx={{ color: "white" }} />,
      permissionName: UserRoles.PricingHistoricPricing,
      hideOnMobile: true,
    },
    {
      title: "Brand Images",
      path: consts.routerPath.brandImages,
      constName: consts.page.brandImages,
      icon: <ImageOutlined fontSize="small" />,
      permissionName: UserRoles.ProductBrandImages,
      hideOnMobile: true,
    },
    {
      title: "Product Messaging",
      path: consts.routerPath.messaging,
      constName: consts.page.messaging,
      icon: <EmailOutlined fontSize="small" />,
      permissionName: UserRoles.ProductMessaging,
      hideOnMobile: true,
    },
    {
      title: "Settings",
      path: consts.routerPath.settings,
      constName: consts.page.settings,
      icon: <SettingsOutlined fontSize="small" />,
    },
    {
      title: "My Apps",
      path: "https://myapps.microsoft.com/",
      constName: "",
      icon: <Microsoft fontSize="small" />,
      hideOnMobile: true,
    },
    {
      title: "Help",
      path: consts.routerPath.help,
      constName: consts.page.help,
      icon: <HelpOutlineOutlined fontSize="small" />,
    },
    {
      title: "Release Notes",
      path: consts.routerPath.releaseNotes,
      constName: consts.page.releaseNotes,
      icon: <NewReleasesOutlined fontSize="small" />,
    },
  ];  

  const filteredMenuItems = menuItems.filter((item) => {
    if (isMobile && item.hideOnMobile) {
      return false;
    }

    if (!item.permissionName) {
      return true;
    }

    return hasPermission(item.permissionName, userProfile.roles);
  });

  const [selectedItemIndex, setSelectedItemIndex] = useState(
    filteredMenuItems.findIndex((item) => item.path === location.pathname)
  );

  const handleMenuItemSelected = useCallback(
    (item: ChildItem, index: number) => {
      setSelectedItemIndex(index);
      
      if (item.path.startsWith("https"))
      {
        window.open(item.path)
      }
      else
      {
        onMenuItemSelected(item.path);
      }
    },
    [onMenuItemSelected]
  );

  return (
    <StyledNav>
        <NavigationBar type="primary" show={isMenuOpen} onClose={onDrawerClose}>
        <StyledGridContainer container component="li" className="title" alignItems="center" paddingX={2} paddingY={1.5}>
          <Grid item height="40px">
            <Button
              type="iconOnly"
              onClick={onDrawerClose}
              icon={<Close style={{ color: "#fff", fontSize: "24px" }} />}
            />
          </Grid>
          <Grid item flexGrow={1} textAlign="center">
            Menu
          </Grid>
        </StyledGridContainer>

        {canSwitchCountries && (
          <StyledGridContainer
            container
            component="li"
            alignItems="center"
            paddingLeft={3}
            paddingRight={2}
            paddingY={1.5}
          >
            <Grid item height="20px">
              <LocationOnOutlined sx={{ color: "#fff", fontSize: "20px" }} />
            </Grid>
            <Grid item marginLeft={2} flexGrow={1}>
              Country
            </Grid>
            <Grid item>
              <SquareSwitch leftText="AU" rightText="NZ" currentCountry={country} onChange={changeCountry} />
            </Grid>
          </StyledGridContainer>
        )}

        {useMemo(
          () =>
            filteredMenuItems.map((item, index) => {
              return (
                <NavigationItem
                  key={index}
                  text={item.title}
                  icon={item.icon}
                  isActive={selectedItemIndex === index}
                  onSelect={() => handleMenuItemSelected(item, index)}
                />
              );
            }),
          [filteredMenuItems, selectedItemIndex, handleMenuItemSelected]
        )}

        <NavigationFooter>
          <NavigationItem icon={<Logout />} text="Logout" onSelect={onLogout} />
        </NavigationFooter>
      </NavigationBar>
    </StyledNav> 
  );
};
